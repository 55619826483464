


import {
  IonPage, IonContent ,
  IonHeader,
  IonItem,
  IonMenu,
  IonRouterOutlet,
  IonApp,
  IonList,
  IonLabel,
  IonListHeader,
  IonMenuToggle,
  IonToolbar,
  IonButton,
  IonFooter,
  IonIcon,
  IonTitle,
  IonButtons,
  IonSpinner,
  IonAvatar,
  IonNote,
  IonCol, IonRow,
  IonBackButton,
  alertController,
  IonBadge,
  IonItemDivider,
  IonItemGroup,
  IonAlert,
  IonInput,
  IonAccordion,
  IonAccordionGroup,
  IonSkeletonText
} from '@ionic/vue';

import {trashOutline, saveOutline, chevronBack, chevronForward,chevronBackCircle, personCircle, chatbox, addCircleOutline, removeCircleOutline, search, add, remove } from 'ionicons/icons';

import { Options , Vue} from 'vue-class-component';
import fintailapi from "../api";
import {useStore} from "vuex";
import { defineComponent } from 'vue';
import  SafeArea  from '../components/SafeArea.vue'




export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonItem,
    IonAlert,
    IonList,
    IonMenu,
    IonInput,
    IonButton,
    IonTitle,
    IonToolbar,
    IonApp,
    IonIcon,
    IonLabel,
    IonSpinner,
    IonItemDivider,
  IonItemGroup,
    IonListHeader,
    IonMenuToggle,
    IonFooter,
    IonBackButton,
    IonAccordion,
    IonAccordionGroup,
    IonNote,
    IonSkeletonText,
    IonCol,
    IonAvatar,
    IonRow,
    IonBadge,
    SafeArea
  },
  data() {
    return {
      loading:false,
      nomeProduto: "",
      onClear : false,
      onSave  : false,
      isLast: false,
      store : useStore(),
      trashOutline, saveOutline, chevronBack, chevronForward, chevronBackCircle, personCircle, chatbox, addCircleOutline, removeCircleOutline, search, add, remove,
      isOpenRef : false
    }
  },
  computed: {
    carts() {
      return this.store.getters.cartsGroup;
    },

    cartItemNumber() {
      return this.store.getters.cartItemNumber
    },
    cartTotal() {
      return this.store.getters.cartTotal
    }
  },
  created(){

    if(this.store.state.user.isGuest){
        this.convidado()
        return
    }else{
      this.loading = true
      setTimeout(() => {
        this.getItems()
      },500)

    }


  },
  methods: {
    carts_product(products:any) {

      let filter = ""
      if (this.nomeProduto) {
        filter = this.nomeProduto

        //console.log(products);
        return products.filter( (poke:any) =>

        poke.name.toLowerCase().includes(filter.toLowerCase())

        )

      } else {
        return products;
      }

    },
    setOpen(state: boolean){
       this.isOpenRef = state
    },
    voltar() {
      this.$router.back()
    },
    goProduto (produto : any) {
      this.$router.push("/produto/"+produto.id_jau)
    },
    navigate (url : string) {
      this.$router.push(url)
    },
    alertaLista () {
    const alert = alertController.create({
      header: 'Lista de Compras',
      subHeader: '',
      message: 'Crie uma lista para facilitar suas futuras compras.',
      buttons: [
        {
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {

                this.onSave = false;

            }
        },
        {
            text: 'Salvar',
            handler: (alertData) => { //takes the data
                fintailapi
                .post('/carrinho/salvar', { "customer_id": this.store.state.user.id, "baskets_id": this.store.state.basket.basket_id, "lista" : { "nome" : alertData.nome_lista} })
                .then((response:any) => {

                  this.onSave = false;
                  this.alerta()


                })
            }
        }
    ],
      inputs: [
        {
          placeholder: 'Nome da Lista',
          name: 'nome_lista',
          type: 'text'
        }
      ]
    }).then((response:any) => {

      response.present();

    });

    //await alert.present();
  },

  getItems(){

    fintailapi
    .post('/carrinho/items', { "baskets_id": this.store.state.basket.basket_id })
    .then((response:any) => {

      this.loading = false
      this.store.state.basket    = response.data.basket;
      // this.store.state.basket = response.data.basket;


    }).catch((error) => {
      this.loading = false
    })

  },
  convidado(){
    const alert = alertController.create({
      header: 'Convidado',
      subHeader: '',
      message: 'Para usar essa função você necessita estar logado',
      buttons: [
        {
            text: 'Voltar',
            role: 'cancel',
            handler: () => {

            }
        },
        {
            text: 'Login',
            handler: (alertData) => { //takes the data
              this.navigate("/login")
            }
        }
    ]
    }).then(res => {

      res.present();

    });

  },

  alerta() {
    const alert = alertController.create({
      header: 'Pronto!',
      subHeader: 'Você criou uma nova lista de compras!',
      message: 'Para consultá-la, acesse o ícone LISTA DE COMPRAS no menu principal',
      buttons: ['OK'],
    }).then((response:any) => {

      response.present();

      })
  },

  clearCart () {


    const alert = alertController.create({
      header: 'Atenção',
      subHeader: '',
      message: 'Tem certeza que deseja excluir todos os produtos do carrinho de compras?',
      buttons: [
        {
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {

            }
        },
        {
            text: 'Sim',
            handler: (alertData) => { //takes the data

              this.onClear = true;

              fintailapi
              .post('/carrinho/limpar', { "baskets_id": this.store.state.basket.basket_id })
              .then((response:any) => {

                this.store.state.basket = response.data.basket;
                this.voltar();

                this.onClear = false;

              }).catch((error) => {

                this.onClear = false;
                this.voltar();

              })



            }
        }
    ]
    }).then(res => {

      res.present();

    });


  },

  saveCart () {

    if(this.store.state.user.isGuest){
      this.convidado()
      return
    }

    this.onSave = true;

    this.alertaLista()

  }



  }
});


/*
@Options({components: {
    IonContent,
    IonPage,
    IonItem,
    IonList,
    IonMenu,
    IonButton,
    IonTitle,
    IonToolbar,
    IonApp,
    IonIcon,
    IonLabel,
    IonListHeader,
    IonMenuToggle,
    IonFooter,
    IonCol,
    IonRow,
    IonSpinner,
    IonAvatar,
    IonNote,
    IonBackButton,
    alertController,
    SafeArea,
    IonBadge,
    IonItemDivider,
  IonItemGroup
},
  computed: {
    carts() {

        let filter = ""
        if (this.nomeProduto) {
          filter = this.nomeProduto
          console.log(this.$store.getters.cartsGroup)
          return this.$store.getters.cartsGroup.filter( (poke:any) =>
            poke[0].cidade_loja.toLowerCase().includes(filter.toLowerCase())
          )
        } else {
          return this.$store.getters.cartsGroup;
        }

    },
    cartItemNumber() {
      return this.$store.getters.cartItemNumber
    },
    cartTotal() {
      return this.$store.getters.cartTotal
    }
}})

export default class Suporte extends Vue {

  store = useStore();

  iconRemove = allIcons.trashOutline
  iconSave = allIcons.saveOutline
  iconVoltar = allIcons.chevronBack

  icon_back = allIcons.chevronBackCircle
  icon_user = allIcons.personCircle
  icon_chat = allIcons.chatbox

  iconPlus  = allIcons.addCircleOutline
  iconMinus = allIcons.removeCircleOutline
  icon_pesquisa = allIcons.search

  iconPlusL  = allIcons.add
  iconMinusL = allIcons.remove

  onClear = false;
  onSave  = false;

  alertaLista = async () => {
    const alert = await alertController.create({
      header: 'Salvar como  Lista Compras ',
      subHeader: 'Digite o nome da Lista ',
      message: 'Salve para comprar no futuro',
      buttons: [
        {
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {
                console.log('Confirm Cancel');
                this.onSave = false;
            }
        },
        {
            text: 'Salvar',
            handler: (alertData) => { //takes the data
                fintailapi
                .post('/carrinho/salvar', { "customer_id": this.store.state.user.id, "baskets_id": this.store.state.basket.basket_id, "lista" : { "nome" : alertData.nome_lista} })
                .then((response:any) => {

                  this.onSave = false;
                  this.alerta()


                })
            }
        }
    ],
      inputs: [
        {
          placeholder: 'Nome da Lista',
          name: 'nome_lista',
          type: 'text'
        }
      ],
    });

    await alert.present();
  };


  convidado(){
    const alert = alertController.create({
      header: 'Convidado',
      subHeader: '',
      message: 'Para usar essa função você necessita estar logado',
      buttons: [
        {
            text: 'Voltar',
            role: 'cancel',
            handler: () => {

            }
        },
        {
            text: 'Login',
            handler: (alertData) => { //takes the data
              this.navigate("/prelogin")
            }
        }
    ],
    }).then(res => {

    res.present();

    });

  };

  alerta = async () => {
    const alert = await alertController.create({
      header: 'Listas de Compras',
      subHeader: '',
      message: 'Sua lista de compras foi salva com sucesso, você pode consultá-la no menu LISTA DE COMPRAS',
      buttons: ['OK'],
    });

    await alert.present();
  };

  clearCart () {

    this.onClear = true;

    fintailapi
    .post('/carrinho/limpar', { "baskets_id": this.store.state.basket.basket_id })
		.then((response:any) => {

      this.store.state.basket     = response.data.basket;
      this.voltar();

      this.onClear = false;

    }).catch((error) => {

      this.onClear = false;
      this.voltar();

    })

  }

  saveCart () {

    if(this.store.state.user.isGuest){
      this.convidado()
      return
    }

    this.onSave = true;

    this.alertaLista()

  }


  navigate (url : string) {
    this.$router.push(url)
  }

  voltar() {
    this.$router.back()
  }


  created() {

    if(this.store.state.user.isGuest){
      this.convidado()
      return
    }

  }

}
*/
